export const jpCommunityManagement = {
  CL10title: 'コミュニティサイト設定',
  CL10ImageSize: '※縦225px以上、横1000px以上を推奨',
  CL10ImageNote: '※画像サイズは自動調整され長方形で表示されます。',
  CL11title: 'コミュニティサイト設定確認',
  CL12SettingComplete: '設定しました。',
  CL10TextConfirm: 'この内容で設定してもよろしいでしょうか。',
  CL10UpdateBtnText: '設定する',
  CL10messageError: 'コミュニティサイト設定の更新に失敗しました。',
}
