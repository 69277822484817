import axios, { AxiosResponse } from 'axios'

const getDummyDataService = async (): Promise<AxiosResponse> => axios.get('https://jsonplaceholder.typicode.com/todos')

const alumnusLoginApi = async (data: LoginPayload): Promise<AxiosResponse> => axios.post('/alumnus/sign_in', data)

const communityLoginApi = async (data: LoginPayload): Promise<AxiosResponse> => axios.post('/employees/sign_in', data)

const alumniLogoutApi = async (): Promise<AxiosResponse> => axios.delete('/alumnus/sign_out')

const communityLogoutApi = async (): Promise<AxiosResponse> => axios.delete('/employees/sign_out')

const getInvitedCommunityApi = async (invited_code: string): Promise<any> => axios.get(`/invited_communities/alumni/${invited_code}`)
const getInvitedEmployeeApi = async (invited_code_employee: string): Promise<AxiosResponse> => axios.get(`/invited_communities/employee/${invited_code_employee}`)

const getAlumniProfileApi = async (): Promise<AxiosResponse> => axios.get('/alumnus/me')
const updateAlumniProfileApi = async (data: any): Promise<AxiosResponse> => axios.put('/alumnus/me', data)
//#184
const getNotificationsApi = async (from_admin_site: boolean = false): Promise<AxiosResponse> => axios.get(`/notifications${from_admin_site ? '?admin_site=true' : ''}`)
//#83
const getNotificationsCommunityApi = async (id: string, from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/notifications${from_admin_site ? '?admin_site=true' : ''}`)
//#84
const getNotificationCommunityDetailApi = async (community_id: string | number, id: string | number, from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/notifications/${id}${from_admin_site ? '?admin_site=true' : ''}`)
//#185
const getNotificationsDetailApi = async (id: string | number, from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.get(`/notifications/${id}${from_admin_site ? '?admin_site=true' : ''}`)
//#16
const getCountUnreadYelloopApi = async (from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.get(`/unread_notification_count${from_admin_site ? '?admin_site=true' : ''}`)
//#25
const getCountUnreadCommunityApi = async (id: string | number, from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/unread_notification_count${from_admin_site ? '?admin_site=true' : ''}`)
const getNotificationTypeApi = async (community_id: string | number, id: string | number, from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/notice/${id}${from_admin_site ? '?admin_site=true' : ''}`)
const authenticationFromOtpToken = async (data: any): Promise<any> => axios.post(`/alumnus/otp/sign_in`, data)

const markAsReadCommunityNotificationsApi = async (community_id: string | number, from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.put(`/communities/${community_id}/notifications/mark_as_read${from_admin_site ? '?admin_site=true' : ''}`)
const markAsReadNotificationsApi = async (from_admin_site: boolean = false): Promise<AxiosResponse> =>
  axios.put(`/notifications/mark_as_read${from_admin_site ? '?admin_site=true' : ''}`)

const userService = {
  getDummyDataService,
  alumnusLoginApi,
  alumniLogoutApi,
  communityLoginApi,
  communityLogoutApi,
  getInvitedCommunityApi,
  getInvitedEmployeeApi,
  getAlumniProfileApi,
  updateAlumniProfileApi,
  getNotificationsApi,
  getNotificationsCommunityApi,
  getNotificationCommunityDetailApi,
  getNotificationsDetailApi,
  getCountUnreadYelloopApi,
  getCountUnreadCommunityApi,
  getNotificationTypeApi,
  authenticationFromOtpToken,
  markAsReadCommunityNotificationsApi,
  markAsReadNotificationsApi,
}

export default userService

export type LoginPayload = {
  email: string
  password: string
  'g-recaptcha-response-data': string
}
