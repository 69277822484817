export const jpConversation = {
  SM10directManager: 'コミュニティ管理者へ問い合わせ',
  SM10partner: '会話の相手',
  SM10lastRemarks: '最新の発言',
  SM10talk: '会話する',
  SM10nochat: 'チャットはありません',
  SM10nochatSearch: '一致する検索結果はありません',
  SM10withdrawn: '脱退済',
  SM10stopped: '一時停止',
  SS10noMessage: 'メッセージはありません',
  SO65delete: '削除する',
  SO65titleConfirm: 'トーク削除',
  SO65contentConfirm: 'トークを削除します。',
  SO65titleConfirmDelete: 'チャット削除',
  SO65contentConfirmDelete: 'チャットを削除します。',
  SO65deleteError: 'チャットの削除に失敗しました',
  SO65deleteSuccess: 'チャットを削除しました。',
  SO65deleteSuccessContent: 'トークを削除しました。',
  SO65deleteSuccessContentEr: 'トークの削除に失敗しました',
  OD65PublishSuccess: '公開に戻しました。',
  OD65PublishError: '公開されませんでした。',
  OD65PrivateSuccess: '非公開にしました。',
  OD65PrivateError: '非公開されませんでした。',
  SN10headerDelete: 'グループの削除',
  SO10headerDelete: 'トークルームの削除',
  SM66header: 'コミュニティ管理者への問い合わせ',
  SM10TitleNotPostId: 'コミュニティ管理者へ問い合わせ',
  SM65backChatList: 'チャット一覧へ',
  SM10myPost: '自分の投稿',
}
