export const enEditCommunity = {
  CO40title: 'コミュニティ編集',
  CO40buttonTop: 'トップへ',
  CO40messageError: 'コミュニテイ更新失敗しました。',
  CO41title: 'コミュニティ編集確認',
  CO40notice: '※非公開にすると管理ユーザ以外はコミュニティに入室できなくなります。',
  CO40noticeImage: '※未設定場合は、ヘッダーロゴが表示されます。',
  CO40text1: 'コミュニティの運営元やメッセージの送信元として公開される法人・組織名を入力ください。',
  CO40text2: '※アップロード可能なファイル形式：.jpg／.jpeg／.png',
  CO40text3: '※縦180px以上、横180px以上を推奨',
  CO40text31: '※縦280px以上、横280px以上を推奨',
  CO40text4: '※ファイル容量上限：20MB',
  CO40interestUserDisplaySetting: '「興味あり」ユーザ表示設定',
  CO40interestUserDisplaySettingNote: '※チェックをOFFにすると、個人ユーザは投稿者以外、誰が興味ありを押したか閲覧できなくなります。',
  CO40settingComment: 'Comment',
  CO40settingMention: 'Mention',
  CO40settingChatting: 'Chat',
  CO40settingInterested: 'Interested',
  CO40emailNoticeRegis: '*When a checked action is performed, an email notification will be sent to the admin user.',
  CO40emailNotificationInstant: 'Immediate email notification',
  CO40NotAllowed: 'Not allowed',
  CO40NotDisplayed: 'Not displayed',
  CO40NotReceived: 'Not received',
}
