import { COMMUNITY_SITE_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { CSPStateTypes } from '.'

export const mutations: MutationTree<CSPStateTypes> = {
  [COMMUNITY_SITE_STORE.MUTATIONS.SET_COMMUNITY_SITE_POSTS](state: CSPStateTypes, payload: { data: any }) {
    setTimeout(() => {
      state.posts = payload.data.records
      state.total_pages = payload.data.total_pages
      state.loadingPosts = false
      state.total_records = payload.data.total_records
    }, 1000)
  },
  [COMMUNITY_SITE_STORE.MUTATIONS.SET_LOADING_CSP](state: CSPStateTypes, payload: boolean) {
    state.loadingPosts = payload
  },
  [COMMUNITY_SITE_STORE.MUTATIONS.SET_COMMUNITY_SITE_POST_DETAIL](state: CSPStateTypes, payload: { data: any }) {
    setTimeout(() => {
      state.post = payload.data
      state.loadingPosts = false
    }, 1000)
  },
  [COMMUNITY_SITE_STORE.MUTATIONS.SET_LIST_COMMENT_CSP](state: CSPStateTypes, payload: any) {
    if (state.post) {
      state.post.comments = payload.data
      state.post.comment_count = payload.data?.length ?? 0
    }
  },
  [COMMUNITY_SITE_STORE.MUTATIONS.SET_COMMENT_CSP](state: CSPStateTypes, payload: any) {
    if (state.post) {
      Object.assign(state.post.comments, [...state.post.comments, payload])
      state.post.comment_count = state.post.comment_count ? state.post.comment_count + 1 : 1
    }
  },
  [COMMUNITY_SITE_STORE.MUTATIONS.DELETE_COMMENT_CSP](state: CSPStateTypes, payload: any) {
    if (state.post) {
      const commentIndex = state.post.comments.findIndex((e: any) => e.id === payload.postCommentId)
      state.post.comments.splice(commentIndex, 1)
      state.post.comment_count = state.post.comment_count > 1 ? state.post.comment_count - 1 : 0
    } else {
      return false
    }
  },
  [COMMUNITY_SITE_STORE.MUTATIONS.UPDATE_LIKE_CSP](state: CSPStateTypes, payload: any) {
    if (state.post) {
      state.post.like = payload.like
      state.post.like_count = payload.like_count
    } else {
      return false
    }
  },
  [COMMUNITY_SITE_STORE.MUTATIONS.SET_USER_MENTION_CSP](state: CSPStateTypes, payload: any) {
    state.userMentionPost = payload
  },
}
