import { MOBILE_WIDTH } from '@/constants/common'
import { removeCookie } from '@/utils/index'
import { createApp } from 'vue'
import App from './index.vue'
import router from './router'
import { store } from './store'

import 'flatpickr/dist/flatpickr.css'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

import axios from 'axios'
import vClickOutside from 'click-outside-vue3'
import MobileDetect from 'mobile-detect'
import { useI18n } from 'vue-i18n'
import VueSocialSharing from 'vue-social-sharing'
import VueClickAway from 'vue3-click-away'
import { PATH, TIME_OUT_API, TIME_OUT_CODE, TRANSACTION_ERROR, FORBIDDEN_OPERATION, ERROR_IP_ADDRESS_NOT_WHITELISTED, FORBIDDEN_COMMUNITY_SITE_POST } from '@/constants/index'

// style
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'nprogress/nprogress.css'
import './index.scss'

// toast
import RequestTimeOutToast from '@/components/timeout/index.vue'
import ErrorToast from '@/components/toast-error/indexErrorToast.vue'
import Toast, { useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import communitySitePostDeleted from '@/components/toast-error/communitySitePostDeleted.vue'
import errorHasChangedData from '@/components/toast-error/errorHasChangedData.vue'

const toast = useToast()

import { i18n } from './languages/i18n'
import { ROOT_STORE } from './store/constants'

// Chart
import { Chart, registerables } from 'chart.js'

//GTAG
import VueGtag from 'vue-gtag'

import commonService from '@/services/common.service'
import { has, throttle } from 'lodash'

Chart.register(...registerables)

// check device
const isSSR = typeof window === 'undefined'
if (!isSSR) {
  const device = new MobileDetect(window?.navigator?.userAgent)
  store.dispatch(ROOT_STORE.ACTIONS.CHECK_MOBILE_DEVICE, !!device.mobile())
  const isPhone: boolean = window?.innerWidth ? window.innerWidth <= MOBILE_WIDTH : false
  store.dispatch(ROOT_STORE.ACTIONS.CHECK_PHONE_DEVICE, isPhone)
}

// create vue app
const app = createApp(App, {
  setup() {
    const { t } = useI18n()
    return { t }
  },
})

axios.defaults.baseURL = `${process.env.VUE_APP_API_ENDPOINT}`
axios.defaults.timeout = TIME_OUT_API
axios.defaults.timeoutErrorMessage = 'timeout'
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
if (process.env.NODE_ENV !== 'development') {
  axios.defaults.withCredentials = true
}

// use third party lib
app.use(VueClickAway)
app.use(VueSocialSharing)
app.use(vClickOutside)
app.use(i18n)
app.use(router)
app.use(store)
app.use(Toast, {
  timeout: false,
  icon: false,
  maxToasts: 1,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false
    }
    // You can modify the toast if you want
    return toast
  },
})
app.use(VueGtag, {
  config: { id: `${process.env.GTAG_ID}` },
})
// mount app
app.mount('#app')

axios.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    //IF add all request post and put then use below code
    //if (config.headers?.common['access-token'] && config.method !== 'get' && config.url !== 'ngword_check')
    if (config.headers?.common['access-token'] && config?.url?.match('/users/me') && config.method === 'put') {
      const CSRFToken = await commonService.getCSRFToken()
      if (CSRFToken?.data?.success) {
        config.headers = { ...config.headers, 'csrf-token': CSRFToken?.data?.data.token }
      }
    }
    if (router.currentRoute.value?.meta?.screenId === 'CT10') {
      config.params = { ...config.params, from_ct_10: true }
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

const communityEmployeeRegistrationPageUrls = ['/community/invited_communities/employee', '/community/join_users', '/community/join_users_confirm']

const showIpRestrictToast = throttle(async () => {
  toast.error({
    component: ErrorToast,
    props: {
      messageCode: 'serverIpRestrictError',
    },
  })
}, 500)

axios.interceptors.response.use(
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  (res) => res,
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const checkRouter = !!(['/community/users/login'].includes(router.currentRoute.value.path) || router.currentRoute.value.path.match('/alumni/invited_communities/alumni/'))

    const data = error?.request?.responseType === 'blob' ? JSON.parse(await error.response.data?.text()) : error?.response?.data

    if (error?.response?.status === 401 && !checkRouter) {
      let pathName = router.currentRoute.value.path.split('/')[1]
      removeCookie(PATH[`${pathName}`]?.header)
      removeCookie(PATH[`${pathName}`]?.pathName)
      router.push({
        path: pathName ? `/${pathName}${pathName === 'community' ? '/users' : ''}/login` : '/alumni/login',
      })
    }

    // 2022/11/23 TrungNT: Handle when server errors transaction
    if (error?.response?.status === 500) {
      const message_code = data?.data[0]?.message_code
      if (message_code === TRANSACTION_ERROR) {
        toast.error({
          component: ErrorToast,
          props: {
            messageCode: 'serverErrorTransction',
          },
        })
      } else {
        let pathName = '/500'
        router.push({ path: pathName })
      }
    }

    if (error?.response?.status === 400) {
      const message_code = data?.data[0]?.message_code
      if (message_code === FORBIDDEN_OPERATION) {
        toast.error({
          component: ErrorToast,
          props: {
            messageCode: 'FORBIDDEN_OPERATION',
          },
        })
      } else if (message_code === 'ERROR_MENTION_USER_INVALID') {
        toast.error({
          component: ErrorToast,
          props: {
            messageCode: 'ERROR_MENTION_USER_INVALID',
          },
        })
      } else if (message_code === 'VALIDATE_NUMBER_OF_MENTION') {
        toast.error({
          component: ErrorToast,
          props: {
            messageCode: 'VALIDATE_NUMBER_OF_MENTION',
          },
        })
      } else if (message_code === 'ERROR_STATUS_HAS_CHANGED') {
        toast.error({
          component: errorHasChangedData,
        })
      }
    }

    const screenId: any = router.currentRoute.value?.meta?.screenId

    if (error?.response?.status === 403) {
      if (error?.response?.config?.url == 'ngword_check') return error

      const { data } = error?.response
      let dataResponse: any = null
      // In case of http-request that has response-type == Blob
      // first we need to convert from Blob object to JS object so that we
      // can extract response-code from its body
      if (data instanceof Blob && data.type.includes('application/json')) {
        const responseBlob = JSON.parse(await data.text())
        dataResponse = responseBlob?.data
      } else {
        dataResponse = data?.data
      }

      // check if user call api request from community member registration flow or not
      // following by redirect to 404 or lobby pages.
      const isFromEmployeeRegistration = communityEmployeeRegistrationPageUrls.find((url) => router.currentRoute.value.path.includes(url))
      if (dataResponse && ERROR_IP_ADDRESS_NOT_WHITELISTED === dataResponse[0].message_code) {
        // This condition prevent toast from show duplicated on the case of page already
        // direct to lobby screen but api from last screen are still requesting
        if (!router.currentRoute.value.path?.includes('lobby') && !router.currentRoute.value.path?.includes('404')) {
          showIpRestrictToast()
        }

        // Handle case when user click navigation button in lobby pages
        // but still want to show restrict IP toast
        if ('/m/tags/popular' === error?.response?.config?.url && error?.response?.config?.params?.from_lobby) {
          showIpRestrictToast()
        }

        // Remove error dialog before direct to lobby pages.
        const modalBackdrop = document.getElementsByClassName('modal-backdrop')
        while (modalBackdrop.length > 0) {
          modalBackdrop[0].parentNode?.removeChild(modalBackdrop[0])
        }
        if (isFromEmployeeRegistration) {
          router.replace({ path: '/404' })
        } else {
          let pathName = router.currentRoute.value.path.split('/')[1]
          router.replace({ path: pathName ? `/${pathName}/lobby` : '/alumni/lobby' })
        }
      } else if (FORBIDDEN_COMMUNITY_SITE_POST === dataResponse[0].message_code && ['SF10', 'SH10', 'SH20'].includes(screenId)) {
        router.replace({ path: '/404' })
      } else if (error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/detail/)) {
        router.replace({ path: '/404' })
      } else if (['SO10', 'SO65', 'SN70'].includes(screenId)) {
        router.replace({ path: '/404' })
      } else if (
        error?.response?.config?.url?.match(/\/communities\/(\d+)\/list\/comment/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/comments\/([a-zA-Z0-9]+)/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/comments/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/mention_users/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/like/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/interest/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/favorite/) ||
        (error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)$/) && error?.response?.config?.method !== 'get')
      ) {
        toast.error({
          component: errorHasChangedData,
        })
      } else {
        let pathName = router.currentRoute.value.path.split('/')[1]
        router.push({ path: pathName ? `/${pathName}/lobby` : '/alumni/lobby' })
      }

      return isFromEmployeeRegistration ? error : null
    }

    if (
      error?.response?.status === 404 &&
      (error?.response?.config?.url?.match(/communities\/(\d+)\/list\/comment/) ||
        error?.response?.config?.url?.match(/communities\/(\d+)\/official_posts\/detail\/([a-zA-Z0-9]+)/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/comments\/([a-zA-Z0-9]+)/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/comments/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/mention_users/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/like/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/interest/) ||
        error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)\/favorite/) ||
        (error?.response?.config?.url?.match(/posts\/([a-zA-Z0-9]+)$/) && error?.response?.config?.method !== 'get'))
    ) {
      toast.error({
        component: errorHasChangedData,
      })
    }

    if (
      error?.response?.status === 404 &&
      (router.currentRoute.value.path.includes('community_site_posts_details') || error?.response?.config?.url?.match(/\/communities\/\d+\/notice\/\d+/)) // api /communities/{number}/notice/{number}
    ) {
      router.replace({ path: '/404' })
    }
    if (error?.response?.status === 404) {
      const listscreenId = ['SM65']
      if (listscreenId.includes(screenId)) {
        router.replace({ path: '/404' })
      }
      const params = error?.config?.params ? error?.config?.params : error?.config?.data ? JSON.parse(error?.config?.data) : {}
      if (params?.from_sf_10) {
        toast.error({
          component: communitySitePostDeleted,
        })
      }
    }
    if (!error?.response || !error?.response?.status) {
      // 2022/09/29 CW-Phuong Fix for UAT Phase2#3808: main (Ph2-R20221007)
      if (error?.code === TIME_OUT_CODE) {
        //  case request time out
        toast.error({
          component: RequestTimeOutToast,
        })
      } else if (!window.navigator.onLine) {
        //  case network is offline
        toast.error({
          component: ErrorToast,
          props: {
            messageCode: 'serveOffline',
          },
        })
      } else {
        //  case request body content is blocked by watfcharm service
        toast.error({
          component: ErrorToast,
          props: {
            messageCode: 'serverErrorWatfCharm',
          },
        })
      }
    }

    return error
  }
)
