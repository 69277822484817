import { COMMUNITY_SITE_ADMIN_STORE, USER_POST_STORE } from '@/store/constants'
import { MutationTree } from 'vuex'
import { UserPostStateTypes } from '.'
import { GetPostType } from '@/store/modules/user-post/user-post.getters'

export const mutations: MutationTree<UserPostStateTypes> = {
  [USER_POST_STORE.MUTATIONS.GET_POST](state: UserPostStateTypes, payload: any) {
    state.posts = payload.records
    state.total_pages = payload.total_pages
    state.loadingPosts = false
    state.total_records = payload?.total_records
  },

  [USER_POST_STORE.MUTATIONS.GET_OPERATIONAL_POST](state: UserPostStateTypes, payload: any) {
    state.posts = payload.records
    state.total_pages = payload.total_pages
    state.loadingPosts = false
    state.total_records = payload?.total_records
  },
  [USER_POST_STORE.MUTATIONS.SET_LOADING_POSTS](state: UserPostStateTypes, payload: any) {
    state.loadingPosts = payload
    // state.total_pages = payload.total_pages
  },

  [USER_POST_STORE.MUTATIONS.UPDATE_POST](state: UserPostStateTypes, payload: any) {
    state.posts = state.posts.map((e) => (e.id === payload.id ? Object.assign(e, payload) : e))
  },

  [USER_POST_STORE.MUTATIONS.ADD_COMMENT](state: UserPostStateTypes, payload: any) {
    const post = state.postDetail.post
    if (post) {
      if (post.comment_count) {
        post.comments = payload.data ? [...post.comments, payload.data] : post.comments
        post.comment_count = payload.data ? post.comment_count + 1 : post.comment_count
      } else {
        post.comments = payload.data ? [payload.data] : []
        post.comment_count = payload.data ? 1 : 0
      }
    }
  },
  [USER_POST_STORE.MUTATIONS.REMOVE_COMMENT](state: UserPostStateTypes, payload: any) {
    const post = state.postDetail.post
    if (!post) {
      return false
    }

    const commentIndex = post.comments.findIndex((e: any) => e.id === payload.postCommentId)
    post.comments.splice(commentIndex, 1)
    post.comment_count = post.comment_count - 1
  },
  [USER_POST_STORE.MUTATIONS.SET_CURRENT_COMMENT](state: UserPostStateTypes, payload: SetCurrentComment) {
    state.comment = state.postDetail.post?.comments?.find((comment) => comment.id === payload.comment_id)
  },

  [USER_POST_STORE.MUTATIONS.UPDATE_STATUS_COMMENT](state: UserPostStateTypes, payload: any) {
    const post = state.postDetail.post
    if (post?.comments) {
      post?.comments.map((comment) => {
        if (comment.id === payload.id) {
          comment.published = payload.published
        }
      })
    }
  },
  [USER_POST_STORE.MUTATIONS.SET_SHOW_ALL_COMMENTS](state: UserPostStateTypes, payload: any) {
    state.showAllComment = payload
  },
  //2022/12/05 PhuongTT Fix for UAT#5451 (Ph2-R20221222)
  [USER_POST_STORE.MUTATIONS.SET_LIST_COMMENT_USER_POST](state: UserPostStateTypes, payload: any) {
    state.postDetail.post.comments = payload.data
    state.postDetail.post.comment_count = payload.data?.length ?? 0
  },

  //2022/11/12 TienNV Fix for UAT#5121: CT10,OF10 (Ph2-R20221116)
  [USER_POST_STORE.MUTATIONS.SET_POST_DETAIL](state: UserPostStateTypes, payload: any) {
    if (payload === 'error') {
      state.postDetail = payload
      return
    }

    state.posts = state.posts?.map((e: any) => {
      if (e.id === payload?.post?.id) {
        e = { ...e, ...payload?.post }
      }
      return e
    })
    state.postDetail = payload
  },
  [USER_POST_STORE.MUTATIONS.REMOVE_POST](state: UserPostStateTypes, payload: string) {
    state.posts = state.posts.filter((item) => item.id !== payload)
  },
  [USER_POST_STORE.MUTATIONS.UPDATE_LIKE_INTEREST_POST](state: UserPostStateTypes, payload: any) {
    state.postDetail.post = payload
  },
  [COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.UPDATE_LIST]: (state: UserPostStateTypes, payload) => {
    state.posts = state.posts?.map((post) => {
      if (post.id === payload.id) {
        post.comment_count = payload.comment_count
      }
      return post
    })
  },
}

export type SetCurrentComment = {
  post_id: string
  comment_id: string
}
