import { AUTH_STORE } from '@/store/constants'
import userService from '@/services/auth.service'
import { NOTIFICATION_TYPE } from '@/constants/common'

type CommitType = {
  commit: any
}

export const actions = {
  [AUTH_STORE.ACTIONS.SET_AUTH_VALUE]({ commit }: CommitType, payload: boolean): void {
    commit(AUTH_STORE.MUTATIONS.SET_AUTH_VALUE, payload)
  },
  [AUTH_STORE.ACTIONS.SET_FORGOT_EMAIL_DATA]({ commit }: CommitType, payload: boolean): void {
    commit(AUTH_STORE.MUTATIONS.SET_FORGOT_EMAIL_DATA, payload)
  },
  async [AUTH_STORE.ACTIONS.SET_NOTIFICATIONS]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await userService.getNotificationsApi(payload.fromAdminSite)
      commit(AUTH_STORE.MUTATIONS.SET_NOTIFICATIONS, data?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },

  async [AUTH_STORE.ACTIONS.SET_NOTIFICATIONS_COMMUNITY]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await userService.getNotificationsCommunityApi(payload.id, payload.fromAdminSite)
      commit(AUTH_STORE.MUTATIONS.SET_NOTIFICATIONS_COMMUNITY, data?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [AUTH_STORE.ACTIONS.SET_NUMBER_UNREAD_YELLOOP]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await userService.getCountUnreadYelloopApi(payload.fromAdminSite)
      commit(AUTH_STORE.MUTATIONS.SET_NUMBER_UNREAD_YELLOOP, data?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [AUTH_STORE.ACTIONS.SET_NUMBER_UNREAD_COMMUNITY]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await userService.getCountUnreadCommunityApi(payload.id, payload.fromAdminSite)
      commit(AUTH_STORE.MUTATIONS.SET_NUMBER_UNREAD_COMMUNITY, data?.data?.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  // 2022/10/07 CW-TrungNT Fix UAT#4489: SR10 (Ph2-R20221014)
  async [AUTH_STORE.ACTIONS.CLEAR_NUMBER_UNREAD_NOTIFICATIONS]({ commit }: CommitType): Promise<void> {
    commit(AUTH_STORE.MUTATIONS.SET_NUMBER_UNREAD_YELLOOP, {})
    commit(AUTH_STORE.MUTATIONS.SET_NUMBER_UNREAD_COMMUNITY, {})
  },
  [AUTH_STORE.ACTIONS.CHANGE_IS_READ]({ commit }: CommitType, payload: boolean): void {
    commit(AUTH_STORE.MUTATIONS.CHANGE_IS_READ, payload)
  },
  async [AUTH_STORE.ACTIONS.SET_DETAIL_NOTIFICATIONS]({ commit }: CommitType, payload: any): Promise<void> {
    if (payload?.role === 'community') {
      try {
        let data: any = {}
        if (payload?.notification_type === NOTIFICATION_TYPE.TYPE) {
          data = await userService.getNotificationTypeApi(payload?.id, payload?.id_notification, payload?.fromAdminSite)
        } else {
          data = await userService.getNotificationCommunityDetailApi(payload?.id, payload?.id_notification, payload?.fromAdminSite)
        }
        commit(AUTH_STORE.MUTATIONS.SET_DETAIL_NOTIFICATIONS, data?.data?.data)
        commit(AUTH_STORE.MUTATIONS.CHANGE_IS_READ, { role: payload?.role, id: payload?.id_notification })
      } catch (error) {
        console.log('err', error)
      }
    } else {
      try {
        const data = await userService.getNotificationsDetailApi(payload?.id_notification, payload?.fromAdminSite)
        commit(AUTH_STORE.MUTATIONS.SET_DETAIL_NOTIFICATIONS, data?.data?.data)
        commit(AUTH_STORE.MUTATIONS.CHANGE_IS_READ, { role: payload?.role, id: payload?.id_notification })
      } catch (error) {
        console.log('err', error)
      }
    }
  },
}
